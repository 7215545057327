.header {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: flex-start;*/
    /*align-items: flex-start;*/
    width: 100%;
    padding: 0 0 28px 0;
    border-bottom: 1px solid var(--separator);
}

.title {
    display: block;
    color: rgba(254,254,254,1);
    font-size: 60px;
    text-transform: capitalize;
    margin-bottom: 0;
    margin-left: 28px;
    font-family: "Test Founders Grotesk", sans-serif;
    font-style: normal;
    font-weight: 300;
    padding-top: 10px;
    line-height: 50px;
    letter-spacing: 10px;
}

.title a {
    text-decoration: none;
    color: rgba(254,254,254,1);
}

.title a:hover {
    color: var(--highlight);
}
.subtitle {
    color: rgba(255,250,250,1);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    text-transform: capitalize;
    margin: 0;
    /*margin-top: 15px;*/
    margin-left: 28px;
    padding: 0;
    padding-bottom: 7px;

}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 28px;
}

.menu-item {
    color: var(--highlight);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    /*letter-spacing: 0.015em;*/
    text-transform: capitalize;
    margin-right: 0.6875em;
    text-decoration: none;
}
