/* SelectStyles.css */
.messengerType {
    display: flex;
    justify-content: space-between;
    margin-left: 28px;
}

#messenger .reactSelect__control {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    /*padding-left: 0.2em;*/
}

#messenger .reactSelect__control:hover {
    border-color: var(--highlight);
}

#messenger .reactSelect__placeholder {
    color:var(--shaded_text);
    font-size: 40px;
    font-family: 'JetBrains Mono', monospace;
    font-weight: 400;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
    opacity: unset;
}

#messenger .reactSelect__single-value  {
    color: var(--highlight);
    font-size: 40px;
    font-family: 'JetBrains Mono', monospace;
    font-weight: 400;
    letter-spacing: 0.0625em;
    /*text-transform: uppercase;*/
}

#messenger .reactSelect__menu {
    background-color: rgba(51,51,51,1);
    color: white;
    font-size: 40px;
    width: 15.75em;
}

#messenger .reactSelect__option {
    color: white;
    background-color: transparent;
    width: 100%;
    /*text-transform: uppercase;*/
    font-family: 'JetBrains Mono', monospace;
}

#messenger .reactSelect__option--is-selected {
    color: var(--shaded_text);
}

#messenger .reactSelect__value-container--has-value div::after {
    content: "↓";
    font-size: 40px;
    color: var(--highlight);
    font-weight: 400;
    font-family: 'JetBrains Mono', monospace;
    padding-left: 10px;
}

#messenger .reactSelect__value-container--has-value + .reactSelect__indicators {
    display: none;
}

#messenger .reactSelect__option--is-focused {
    background-color: rgba(211,236,39,0.1);
}

#messenger .reactSelect__option:hover {
    background-color: rgba(255,255,255,0.1);
}

#messenger .reactSelect__multiValue {
    font-size: 40px;
    background-color: rgba(211,236,39,0.1);
}

#messenger .reactSelect__indicator-separator {
    display: none;
}
#messenger .reactSelect__indicator svg {
    display: none;
}
#messenger .reactSelect__indicator::after {
    content: "↓";
    font-size: 40px;
    color: var(--shaded_text);
    font-weight: 400;
    font-family: 'JetBrains Mono', monospace;
}



#messenger .reactSelect__multi-value {
    background-color: rgba(255,255,255,0.2);
    color: white;
}
#messenger .reactSelect__multi-value__label {
    color: white;
    font-size: 40px;
    font-family: 'JetBrains Mono', monospace;
    font-weight: 400;
    letter-spacing: 2px;
    /*text-transform: uppercase;*/
}

#messenger .reactSelect__clear-indicator {
    display: none;
}
#messenger .reactSelect__input-container .reactSelect__input {
    font-size: 0.9375em !important;
    color: white !important;
    /*padding-left: 0.47em !important;*/
    font-weight: 400;
}

#messenger .reactSelect__value-container {
    padding: 0;
}


#messenger #react-select-7-option-0,
#messenger  #react-select-2-option-0,
#messenger  #react-select-3-option-0,
#messenger  #react-select-4-option-0,
#messenger  #react-select-5-option-0

{
    color: white;
    text-transform: none;
    padding: 0 20px 0 10px;
    height: 25px;
    font-size: 20px;
    text-align: right;
    font-weight: normal;
}

/*#react-select-7-listbox {*/
/*    margin-top: -170px;*/
/*    margin-left: 428px;*/
/*}*/
