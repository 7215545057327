.search-panel {
    display: flex;
    flex-wrap: wrap;
}
.extra-large-window {
 display: block;
}

.input-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid var(--separator);
}

.transient,
.object,
.coordinates,
.event,
.icon-container,
.messenger,
.radius,
.messengerContainer
{
    padding: 30px 0 30px 0;
}

.label {
    /*display: flex;*/
    align-items: center;
    /*justify-content: center;*/
    color: var(--shaded_text) !important;
    font-size: 40px;
    font-family: 'JetBrains Mono', monospace;
    font-weight: 400;
    letter-spacing: 0.0625em;
    padding-right: 10px;
    text-transform: uppercase;
}


.input-group input {
    width: 90%;
    background: transparent;
    border: solid 1px rgba(0,0,0,0);
    color: var(--highlight);
    font-size: 40px;
    font-family: 'JetBrains Mono', monospace;
    font-weight: 400;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border 0.3s ease;
    padding: 0;
}

.input-group input:focus {
    outline: none;
    font-size: 40px;
    /*border-bottom: 1px solid var(--highlight);*/
}

.input-group input::placeholder {
    color: rgba(255,255,255,1);
    line-height: 0.75em;

}

.input-group input:focus::placeholder {
    opacity: 0;
}



.input-group .astromap{
    padding: 0;
    margin: 0;
}

.astromap {
    width: 10%;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    text-align: right;
}

.astromap a {
    display: block;
    color: var(--shaded_text);

}

.astromap a:hover {
    color: white;
}

.input-group .icon-container {
    padding: 0.2em 0 0 10px;
    margin: 0;
}

.object, .transient, .messenger {
    width: 50%;
}

.event, .messenger {
    flex: 1;
    width: 50%;
    border-left: 1px solid var(--separator);
}

.extra-large-window .transient
, .extra-large-window .event
, .extra-large-window .object
, .extra-large-window .messenger
{
    width: 100%;

}


.coordinatesContainer input::placeholder {
    text-align: left;
    /*background: url(underline.png) no-repeat bottom center;*/
    color: var(--shaded_text);
    opacity: unset;
}
.hidden-span {
    border: solid 1px rgba(0,0,0,0);
    font-size: 40px;
    font-family: 'JetBrains Mono', monospace;
    font-weight: 400;
    letter-spacing: 0.0625em;
    text-transform: uppercase;
    visibility: hidden;

}

.large-window .search-button
, .extra-large-window .search-button
{
    position: absolute;
    bottom: 0;
}

