#root {
    color: white;
}

.columns {
    display: flex;
    width: 100%;
}
.sidemenu {
    /*flex: 1;*/
    margin-right: 20px;
    font-size: 20px;
    font-weight: 500;
    /*width:35%;*/
    width: 410px;
    padding-bottom: 10em;
    /*max-height: 100vh;*/
    line-height: 32px;
    overflow-y: auto;
    scrollbar-width: none;
}

.sidemenu::-webkit-scrollbar {
    display: none;
}

.sidemenu button {
    margin-left: 9em;
    margin-top: 1em;
    background-color: var(--highlight);
    border: 0;
    padding: 0.5em;
}

.main {
    flex: 2;
    width:65%;
}


.cards-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-right: 1px solid var(--separator);
}

.card-item {
    /*margin-bottom: 10px;*/
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-bottom: 1px solid var(--separator);
}
.card-header {
    display: flex;
    width: 100%;
}

.card-item:hover {
    background-color: rgba(var(--highlight-rgb), 0.2);
    color: #ffffff;
    /*transition: color 0.3s ease;*/
}

.card-item:active {
    background-color: rgba(var(--highlight-rgb), 0.3);
    /*color: #000;*/
    /*transition: color 0.3s ease;*/
}
.card-item.active-card:active {
    background-color: rgba(var(--highlight-rgb), 0.9);
    color: black;
}

.card-item.active-card {
    background-color: rgba(var(--highlight-rgb), 0.9);
    color: black;
}

.card-date, .card-title, .card-id {
    margin-bottom: 10px;
}
.card-date {
    flex: 2;
}

.card-date, .card-id {
    font-size: 15px;
}
.card-title {
    padding-right: 50px;
}
.card-date {
    flex: 2;
}
.card-id {
    font-weight: 400;
    text-align: right;
    flex: 1;
    font-size: 14px;
}

.main-card-header {
    padding-top: 20px;
    display: flex;
    width: 95%;
}

.main-card-title {
    font-size: 35px;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-right: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 150% */
    letter-spacing: 0.5px;
}

.main-card-message {
    font-size: 20px;
    padding-right: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    letter-spacing: 0.5px;
}


.main-card-creator {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px; /* 160% */
    letter-spacing: 0.5px;
    padding-right: 300px;
}
.main-card-id {
    text-align: right;
    flex: 1;
}
.main-card-date, .main-card-id {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.tagList {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    list-style-type: none;
    padding: 20px 0 0;
}
.tagList li {
    font-weight: lighter;
    color: black;
    background-color: var(--highlight);
    border: solid 1px black;
    padding: 5px 10px;
    font-size: 0.6em;
}
.main-card-message a {
    color: var(--highlight);
    text-decoration: none;
}

.main-card-message a:hover {
    color: white;
}



