
.transientContainer {
    display: flex;
    justify-content: space-between;
    /*margin-left: 0.55em;*/
}


.transientContainer input::placeholder {
    /*background: url(underline.png) repeat-x bottom left;*/
    color: var(--shaded_text);
    opacity: unset;
}

.transientContainer,
.flow-input-container{
    /*width: 100%;*/
}
.transientContainer input {
    margin: 0 0.2em;
    /*padding-bottom: 10px;*/
    padding-left: 20px;
    width: 95%;
}

/*.hidden-span {*/
/*    border: solid 1px rgba(0,0,0,0);*/
/*    font-size: 1.25em;*/
/*    font-family: 'JetBrains Mono', monospace;*/
/*    font-weight: 400;*/
/*    letter-spacing: 0.0625em;*/
/*    text-transform: uppercase;*/
/*    visibility: hidden;*/

/*}*/