p {
    color: #fff;
}

.starmap_container {
    display: flex;
}

.sidebar {
    /*margin-top: 1em;*/
    /*margin-left: 1em;*/
    /*padding: 0.5em;*/
    background-color: black;
    width: 280px;
    /*margin-right: -280px;*/
    /*border: 2px solid var(--separator);*/
}

.sidebar div {
    margin-bottom: 5px;
}

.getCoords {

    margin-left:28px;
    background-color: var(--highlight);
    width: 155px;
    height: 155px;
    border: 0;
    font-size: 20px;
    text-align: center;
}

.getCoords:hover {
    filter: brightness(110%);
    cursor: pointer;

}

.getCoords span {
    /*font-size: 0.6em;*/
    text-align: left;
}

.coords, .coords_flow,
.coords_selected,
.angular,
.angular_slider,
.info {
    display: block;
    color: #fff;
    font-size: 20px;
}

.coords_selected, .coords_flow, .info{
    margin-top: 1em;
    padding-left: 28px;
    /*margin-left: 2em;*/
}
.angular_container {
    padding-left: 28px;
}
.angular_slider {
    margin-top: 10px;
    width: 200px;
}

.coords_selected{
    padding-bottom: 20px;
    /*border-bottom: 1px solid var(--separator);*/
}

.info {
    /*height: 300px;*/
    overflow: scroll;
    font-size: 20px;
    color: var(--shaded_text);
    padding-top: 20px;
    /*border-top: 1px solid var(--separator);*/
}
.coords_flow {
    padding-top: 20px;
    /*border-top: 1px solid var(--separator);*/
    color: var(--shaded_text);
}
.angular_input {
    background-color: black;
    border: 0;
    text-decoration: underline;
    color: var(--highlight);
    width: 2em;
    /*padding-top: 1em;*/
    font-size: 20px;

}

.selected_ra_input {
    color: var(--highlight);
}


.angular_input:focus {
    outline: none;
    background-color: var(--shaded_text);
    color: black;
    text-decoration: none;
}



