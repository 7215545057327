@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono'), url('./JetBrainsMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    background-color: black;
}

:root {
    --highlight: #D3EC27;
    --highlight-rgb: 211, 236, 39;
    --shaded_text: #4d4d4d;
    --flow_input: #777;
    --separator: #333333;
}
