.collapsed_search_container {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--separator);
}

.founded-objects {
    flex: 1;
    font-size: 40px;
    font-family: "JetBrains Mono", sans-serif;
    background-color: black;
    padding: 30px;
    color: var(--highlight);
}

.back_to_search {
    width: 3.85em;
    height: 3.85em;
    background-color: var(--highlight);
    border: none;
    cursor: pointer;
    outline: none;
    text-align: center;
    /*padding: 0 0.8em;*/

}

.search-icon {
    width: 2.5em;
    height: 2.5em;
    margin-top: 0.6em;
    margin-left: 0.45em;
    background-color: var(--highlight);
}

