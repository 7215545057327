.search-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 155px;
    letter-spacing: 1em;
    background-color: var(--highlight);
    transition: filter 0.3s ease;
}


.search-button:hover {
    filter: brightness(110%);
    cursor: pointer;
}

.search-button:active {
    filter: brightness(80%);
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.search-text {
    color: rgba(0,0,0,1);
    font-size: 40px;
    line-height: 0.75em;
    font-family: 'JetBrains Mono', monospace;
    font-weight: 400;
    letter-spacing: 1em;
    text-transform: uppercase;
    padding-left: 28px;
}

.search-icon {
    width: 2.5em;
    height: 2.5em;
    margin-right: 10px;
}

.icons-container {
    display: flex;
    align-items: center;
    padding-right: 1em;
}

@keyframes rotatePupil {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.pupil-spinning {
    transform-origin: 42px 42px;
    animation: rotatePupil 2s linear infinite;
}


